@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import 'ng-zorro-antd/ng-zorro-antd.min.css';

:root {
  /* Colors */
  --primary-light: #5eead4;
  --primary: #14b8a6;
  --primary-dark: #0f766e;
  --secondary-light: #bae6fd;
  --secondary: #0ea5e9;
  --secondary-dark: #0369a1;
  --white: #ffffff;
  --black: #000000;

  /* Spacing */
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
}

/**
 * ng-zorro overrides
 */

// nz-switch
button.ant-switch {
  background: buttonface;
}

button.ant-switch-checked {
  background: #1890ff;
}

// nz-layout
.ant-layout-content {
  flex: 1;
  display: flex;
  flex-flow: column;

  .inner-content {
    flex: 1;
  }
}

// nz-modal
.ant-modal-content {
  max-height: 80vh;

  display: flex;
  flex-direction: column;
}

.ant-modal-body {
  overflow-y: auto;
}

// nz-descriptions
.ant-descriptions-item-label {
  font-weight: bold;
}

// nz-timeline
.timeline-left {
  .ant-timeline-item-label {
    width: calc(10% - 12px) !important;
  }

  .ant-timeline-item-content {
    left: calc(11% - 4px) !important;
    width: calc(89% - 4px) !important;
  }

  .ant-timeline-item-tail,
  .ant-timeline-item-head {
    left: 11% !important;
  }
}
